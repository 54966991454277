import React from 'react';
import { Link } from 'gatsby';
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import classNames from 'classnames/bind';

import styles from './BigCalloutLinks.module.scss';
import { PrismicLink } from 'utils/renderHelpers';
const cx = classNames.bind(styles);

export default ({ primary, items, slug }) => {
  return (
    <Section
      className={`${primary.theme || `white`} ${cx({
        section: true,
        [primary.theme || `white`]: true
      })}`}
    >
      <Container className="has-text-centered">
        {items.map((item, idx) => {
          const isModalLink = item.modal_link && item.modal_link.length > 1;
          // console.log(item, isModalLink);
          return (
            <React.Fragment key={`biglinks-${idx}`}>
              {idx > 0 && <br />}
              {isModalLink ? (
                <Link
                  to={`/${slug}/${item.modal_link}`}
                  className={styles.button}
                >
                  {item.link_label.text}
                </Link>
              ) : (
                <PrismicLink
                  link={item.link}
                  label={item.link_label}
                  className={styles.button}
                />
              )}
            </React.Fragment>
          );
        })}
      </Container>
    </Section>
  );
};
