import {
  faCloudDownloadAlt,
  faHeart as faHeartSolid,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";

import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Section from "react-bulma-components/lib/components/section";
import classNames from "classnames/bind";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { renderText } from "utils/renderHelpers";
import styles from "./Gallery2024.module.scss";

const cx = classNames.bind(styles);

const like = (url) => {
  if (typeof window === "undefined") return;
  const id = url.split("/").pop().split(".")[0];
  localStorage.setItem(`like-${id}`, "true");
};

const unLike = (url) => {
  if (typeof window === "undefined") return;
  const id = url.split("/").pop().split(".")[0];
  localStorage.removeItem(`like-${id}`);
};

const isLiked = (url) => {
  if (typeof window === "undefined") return;
  const id = url.split("/").pop().split(".")[0];
  const item = localStorage.getItem(`like-${id}`);
  return item === "true" || item === true;
};

function GallerySection({ children, theme }) {
  return (
    <Section className={`${theme} ${cx({ section: true, [theme]: true })}`}>
      <Container>{children}</Container>
    </Section>
  );
}

const GalleryItem = React.forwardRef(({ idx, item, onClick }, ref) => {
  const { image } = item;
  const { alt } = image;
  const thumbnailUrl = `${image.url}&w=400&h=400&fit=crop&crop=faces&auto=format&q=60`;

  return (
    <figure ref={ref} className={cx({ thumbnail: true })}>
      <button onClick={onClick} title={alt}>
        <div>
          <img loading="lazy" src={thumbnailUrl} alt={alt} />
        </div>
      </button>
    </figure>
  );
});

function FullSizeGalleryItem({
  item,
  current,
  next,
  prev,
  share,
  idx,
  onClick,
}) {
  const first = React.useRef(true);
  const loadedTimer = React.useRef(null);
  const itemRef = React.useRef(null);
  const { image } = item;
  let dimensions = {};
  let alt = "";
  if (image) {
    alt = image.alt;
    dimensions = image.dimensions || {};
  }
  const url = `${image?.url}&w=1920&h=1080&auto=format&q=90`;
  const strippedUrl = image?.url?.split("?")[0];
  const downloadUrl = `${strippedUrl}?q=100&fm=jpg&dl=${
    getDownloadFilename(alt) || `image-${idx}`
  }.jpg`;
  const [isImageLiked, setIsImageLiked] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [hasInitiated, setHasInitiated] = React.useState(current | next | prev);

  React.useEffect(() => {
    if (isLoaded) {
      clearTimeout(loadedTimer.current);
    } else {
      if (current || next || prev) {
        setHasInitiated(true);
        loadedTimer.current = setTimeout(() => {
          setIsLoaded(true);
        }, 3000);
      }
    }
  }, [isLoaded, current, next, prev, setHasInitiated]);

  React.useEffect(() => {
    if (first.current) {
      setIsImageLiked(isLiked(url));
      first.current = false;
    }
  }, [url]);

  if (!image) {
    return null;
  }

  return (
    <div
      ref={itemRef}
      onClick={onClick || null}
      style={{ cursor: onClick ? "pointer" : "default" }}
      className={cx({
        reelItem: true,
        current: current === true,
        next: next === true,
        prev: prev === true,
      })}
    >
      <div
        className={cx({ holder: true })}
        style={{
          aspectRatio: `${dimensions.width}/${dimensions.height}`,
        }}
      >
        <div className={cx({ wrapper: true })}>
          <figure>
            <img
              src={
                current || next || prev ? url : hasInitiated ? url : undefined
              }
              loading={current || next || prev ? "eager" : "lazy"}
              decoding="async"
              alt={alt}
              style={{ display: current || next || prev ? "block" : "none" }}
              className={cx({ loaded: isLoaded })}
              onLoad={() => setIsLoaded(true)}
            />
            {/*<figcaption>{alt}</figcaption>*/}
            <button
              title={`Share this image`}
              tabIndex={current ? 0 : -1}
              disabled={!current}
              className={cx({ shareButton: true })}
              onClick={() => {
                share(itemRef.current);
              }}
            >
              <span className="sr-only">Share this image</span>
              <FontAwesomeIcon
                icon={faShareSquare}
                className={cx({ heart: true })}
              />
            </button>{" "}
            <a
              title="Save this image"
              href={downloadUrl}
              tabIndex={current ? 0 : -1}
              disabled={!current}
              className={cx({ downloadButton: true })}
              download
            >
              <span className="sr-only">Save this image</span>
              <FontAwesomeIcon
                icon={faCloudDownloadAlt}
                className={cx({ heart: true })}
              />
            </a>
            <button
              title={
                isImageLiked
                  ? "You've added this image to your favourites"
                  : "Add this image to favourites"
              }
              tabIndex={current ? 0 : -1}
              disabled={!current}
              className={cx({ likeButton: true, isLiked: isImageLiked })}
              onClick={() => {
                isImageLiked ? unLike(url) : like(url);
                setIsImageLiked(!isImageLiked);
              }}
            >
              <span className="sr-only">
                {isImageLiked
                  ? "You've added this image to your favourites"
                  : "Add this image to favourites"}
              </span>
              <FontAwesomeIcon
                icon={isImageLiked ? faHeartSolid : faHeart}
                className={cx({ heart: true, isLiked: isImageLiked })}
              />
            </button>
          </figure>
        </div>
      </div>
    </div>
  );
}

function Reel({ items, currentIndex, next, prev, share }) {
  const reel = React.useRef(null);
  const slider = React.useRef(null);
  const track = React.useRef(null);
  const [sliderPos, setSliderPos] = React.useState({ x: 0, y: 0 });
  const [shouldAnimate, setShouldAnimate] = React.useState(true);
  const timer = React.useRef(null);

  React.useEffect(() => {
    let startX = 0;
    let startY = 0;
    let distX = 0;
    let distY = 0;

    const threshold = 150; // required min distance traveled to be considered swipe
    const restraint = 100; // maximum distance allowed at the same time in perpendicular direction
    const allowedTime = 300; // maximum time allowed to travel that distance
    let elapsedTime;
    let startTime;

    const handleSwipe = (isRightSwipe) => {
      isRightSwipe ? prev() : next();
      // You can perform actions here based on left or right swipe
    };

    const handleTouchStart = (e) => {
      const touchobj = e.changedTouches[0];
      startX = touchobj.pageX;
      startY = touchobj.pageY;
      startTime = new Date().getTime(); // record time when finger first makes contact with surface
    };

    const handleTouchEnd = (e) => {
      const touchobj = e.changedTouches[0];
      distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
      distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
      elapsedTime = new Date().getTime() - startTime; // get time elapsed
      if (elapsedTime <= allowedTime) {
        // first condition for awipe met
        if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
          // 2nd condition for horizontal swipe met
          handleSwipe(distX > 0); // if dist traveled is positive, it indicates right swipe
        }
      }
    };

    const handleTouchMove = (e) => {};
    const reelElement = reel.current;
    reelElement.addEventListener("touchstart", handleTouchStart, false);
    reelElement.addEventListener("touchmove", handleTouchMove, false);
    reelElement.addEventListener("touchend", handleTouchEnd, false);

    return () => {
      reelElement.removeEventListener("touchstart", handleTouchStart);
      reelElement.removeEventListener("touchmove", handleTouchMove);
      reelElement.removeEventListener("touchend", handleTouchEnd);
    };
  }, [next, prev]);

  React.useEffect(() => {
    const resetAnimateTimer = () => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setShouldAnimate(true);
      }, 600);
    };

    const reposition = () => {
      if (track.current) {
        const currentItem = slider.current.children[currentIndex];
        if (currentItem) {
          setSliderPos({
            x:
              -currentItem.offsetLeft -
              currentItem.offsetWidth * 0.5 +
              track.current.offsetWidth * 0.5 +
              track.current.offsetLeft,
            y: 0,
          });
        }
      }
    };

    const resize = () => {
      setShouldAnimate(false);
      reposition();
      resetAnimateTimer();
    };

    window.addEventListener("resize", resize);

    reposition();
    return () => {
      clearTimeout(timer.current);
      window.removeEventListener("resize", resize);
    };
  }, [currentIndex, setShouldAnimate]);

  // populate an array with indices N items before and after the current item
  const indices = React.useMemo(() => {
    const indices = [];
    for (let i = currentIndex - 4; i <= currentIndex + 4; i++) {
      if (i !== currentIndex && i >= 0 && i < items.length) {
        indices.push(i);
      }
    }
    return indices;
  }, [currentIndex, items]);

  return (
    <div
      ref={reel}
      className={cx({
        reel: true,
        animated: shouldAnimate,
      })}
    >
      <div ref={track} className={cx({ track: true })}>
        <div
          ref={slider}
          className={cx({ slider: true })}
          style={{
            transform: `translate3d(${sliderPos.x}px, 0, 0)`,
          }}
        >
          {items.map((item, idx) => {
            const isPrev = idx < currentIndex && indices.includes(idx);
            const isNext = idx > currentIndex && indices.includes(idx);
            return (
              <FullSizeGalleryItem
                key={`gallery-item-${idx}`}
                idx={idx}
                current={idx === currentIndex}
                prev={isPrev}
                next={isNext}
                share={share}
                item={item}
                onClick={isNext ? next : isPrev ? prev : null}
              />
            );
          })}
        </div>
      </div>
      {/* label */}
      <div className={cx({ label: true })} aria-live="polite">
        {Math.max(currentIndex, 0) + 1} / {items.length}
      </div>
      <div className={cx({ caption: true })} aria-live="polite">
        {items[Math.max(currentIndex, 0)].image.alt}
      </div>
    </div>
  );
}

function getItemUrl(id, alt) {
  // lowercase, replace spaces with dashes, remove non-alphanumeric characters
  const galleryId = id.toLowerCase().replace(/\s/g, "-");
  const itemId = alt.toLowerCase().substr(0, 40).replace(/\s/g, "-");
  return `gallery=${galleryId}&item=${itemId}`;
}

function getDownloadFilename(alt) {
  return alt ? alt.toLowerCase().replace(/\s/g, "-") : null;
}

export function Gallery2024({ id, primary, items, pageId }) {
  const { theme } = primary;
  const { heading } = primary;
  const first = React.useRef(true);
  const forceScroll = React.useRef(false);
  const thumbnailRefs = React.useRef(items.map(() => React.createRef()));
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [toastShowing, setToastShowing] = React.useState(false);
  const [toastPosition, setToastPosition] = React.useState({ x: 0, y: 0 });
  const [toastIn, setToastIn] = React.useState(false);
  const toastTimer = React.useRef(null);

  const updateReel = React.useCallback(() => {
    if (first.current || currentIndex === -1) {
      first.current = false;
      return;
    }
    const reelItem = thumbnailRefs.current[currentIndex].current;
    const gallery = reelItem.closest("section");
    const rect = gallery.getBoundingClientRect();

    function scrollReelItem(reelItem, currentIndex, total) {
      const reel = reelItem.parentNode.parentNode;
      const url = getItemUrl(
        heading.text,
        items[currentIndex].image.alt || `image-${currentIndex}`
      );
      // add the url to the window location search
      window.history.replaceState({}, "", `?${url}`);

      if (currentIndex < 6) {
        reel.scrollTo({ left: 0, behavior: "smooth" });
      } else if (currentIndex > total - 6) {
        reel.scrollTo({
          left: reel.scrollWidth - reel.clientWidth,
          behavior: "smooth",
        });
      } else {
        reelItem.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });
      }
    }

    const isInView = rect.top >= 0 || rect.bottom <= window.innerHeight;
    if (
      forceScroll.current ||
      (isInView &&
        window.innerWidth >= 768 &&
        (rect.top < -100 || rect.bottom > window.innerHeight + 100))
    ) {
      forceScroll.current = false;
      gallery.scrollIntoView({ block: "center", behavior: "smooth" });
      setTimeout(() => {
        scrollReelItem(reelItem, currentIndex, items.length - 1);
      }, 750);
    } else {
      scrollReelItem(reelItem, currentIndex, items.length - 1);
    }
  }, [currentIndex, heading]);

  React.useEffect(() => {
    updateReel();
  }, [currentIndex]);

  const onItemClicked = React.useCallback(
    (idx, button) => {
      setCurrentIndex(idx);
    },
    [currentIndex]
  );

  const share = React.useCallback(
    (galleryItem) => {
      const url = getItemUrl(
        heading.text,
        items[currentIndex].image.alt || `image-${currentIndex}`
      );
      // full url
      const fullUrl = `${window.location.origin}/share?pageId=${pageId}&${url}`;
      const button = galleryItem.querySelector("button");
      // copy url to clipboard
      navigator.clipboard.writeText(fullUrl);
      setToastShowing(true);
      setToastPosition({
        x: button.getBoundingClientRect().left + 16,
        y: button.getBoundingClientRect().top - 30,
      });
    },
    [currentIndex, pageId]
  );

  const next = React.useCallback(() => {
    let nextIndex = currentIndex + 1;
    if (nextIndex > items.length - 1) {
      nextIndex = items.length - 1;
    }
    setCurrentIndex(nextIndex);
  }, [currentIndex]);

  const prev = React.useCallback(() => {
    let prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      prevIndex = 0;
    }
    setCurrentIndex(prevIndex);
  }, [currentIndex]);

  React.useEffect(() => {
    let timer = null;
    const handleResize = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        updateReel();
      }, 10);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [updateReel]);

  React.useEffect(() => {
    // get the gallery and item from the url
    const url = new URL(window.location.href);
    const gallery = url.searchParams.get("gallery");
    const item = url.searchParams.get("item");

    console.log("????", gallery, item);

    if (gallery && item) {
      const id = gallery.toLowerCase().replace(/\s/g, "-");
      const galleryIndex = items.findIndex((gi, idx) => {
        const itemId = gi.image.alt
          ? gi.image.alt.toLowerCase().substr(0, 40).replace(/\s/g, "-")
          : `image-${idx}`;
        return gallery === id && item === itemId;
      });

      if (galleryIndex > -1) {
        setTimeout(() => {
          console.log("SHOULD SCROLL", galleryIndex);
          first.current = false;
          forceScroll.current = true;
          setCurrentIndex(-1);
          setCurrentIndex(galleryIndex);
        }, 1000);
      }
    }
  }, []);

  React.useEffect(() => {
    if (toastShowing) {
      setTimeout(() => {
        setToastIn(true);
      }, 1);
    }
  }, [toastShowing]);

  React.useEffect(() => {
    if (toastIn) {
      clearTimeout(toastTimer.current);
      toastTimer.current = setTimeout(() => {
        setToastIn(false);
      }, 3000);
    } else {
      clearTimeout(toastTimer.current);
      toastTimer.current = setTimeout(() => {
        setToastShowing(false);
      }, 300);
    }
  }, [toastIn]);

  return (
    <GallerySection id={`gallery-${id}`} theme={theme}>
      <Columns>
        <Columns.Column>{renderText(heading, "h2")}</Columns.Column>
      </Columns>
      <Columns>
        <Columns.Column className={cx({ reelContainer: true })}>
          <Reel
            items={items}
            currentIndex={currentIndex}
            next={next}
            prev={prev}
            share={share}
          />
        </Columns.Column>
      </Columns>
      <Columns mobile className={cx({ thumbReel: true })}>
        {items.map((item, idx) => {
          return (
            <Columns.Column
              className={cx({
                thumbReelItem: true,
                current: idx === currentIndex,
              })}
              key={`${id}-item-${idx}`}
              mobile={{ size: 2 }}
              tablet={{ size: 1 }}
              desktop={{ size: 1 }}
              fullhd={{ size: 1 }}
            >
              <GalleryItem
                ref={thumbnailRefs.current[idx]}
                onClick={(e) => {
                  onItemClicked(idx, e.target);
                }}
                initialPose="preEnter"
                pose="enter"
                exitPose="exit"
                className={cx({ gallery: true, [theme]: true })}
                item={item}
              />
            </Columns.Column>
          );
        })}
      </Columns>
      <div
        className={cx({ toast: true, show: toastShowing, in: toastIn })}
        style={{ top: toastPosition.y, left: toastPosition.x }}
      >
        Image URL copied to clipboard!
      </div>
    </GallerySection>
  );
}
