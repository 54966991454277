import React, { PureComponent } from "react";
import posed, { PoseGroup } from "react-pose";
import { renderHtml, renderText } from "utils/renderHelpers";

import BreakpointListener from "components/BreakpointListener";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import GlobalEmitter from "utils/GlobalEmitter";
import Section from "react-bulma-components/lib/components/section";
import axios from "axios";
import classNames from "classnames/bind";
import constants from "utils/constants";
import events from "utils/events";
import { renderFluidImage } from "utils/imageHelpers";
import { sine } from "utils/easing";
import styles from "./Gallery.module.scss";
import uid from "utils/uid";

const cx = classNames.bind(styles);
const { fbAccessToken } = constants;

const timing = 400;
const multiplier = 1;

const config = {
  preEnter: {
    opacity: 0,
    x: 300,
    transition: {
      duration: 0,
    },
  },
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      opacity: {
        type: "tween",
        delay: timing * multiplier * 0.5,
        ease: sine.out,
        duration: timing * multiplier * 0.5,
      },
      x: {
        type: "tween",
        delay: timing * multiplier * 0.25,
        ease: sine.inOut,
        duration: timing * multiplier * 0.75,
      },
    },
  },
  exit: {
    opacity: 0,
    x: -300,
    transition: {
      opacity: {
        type: "tween",
        ease: sine.in,
        duration: timing * multiplier * 0.75,
      },
      x: {
        type: "tween",
        duration: timing * multiplier,
        ease: sine.inOut,
      },
    },
  },
};

const GalleryItem = posed.figure(config);

const GallerySection = ({ children, theme }) => {
  return (
    <Section className={`${theme} ${cx({ section: true, [theme]: true })}`}>
      <Container>{children}</Container>
    </Section>
  );
};

export class ImageGallery extends PureComponent {
  items = [];
  first = true;
  fbGalleryInfo = {};

  constructor(props) {
    super(props);
    this.id = `gallery-${uid()}`;

    this.state = {
      height: this.getHeight(),
      isMobile: BreakpointListener.size === "mobile",
      currentIndex: 0,
    };
  }

  onBreakpoint = ({ newSize }) => {
    if (
      (this.state.isMobile && newSize !== "mobile") ||
      (!this.state.isMobile && newSize === "mobile")
    ) {
      this.setState({ isMobile: newSize === "mobile" });
    }
  };

  onResize = () => {
    this.setState({ height: this.getHeight() });
  };

  getGalleryInfo() {
    return axios.get(`/facebook-albums.json`);
  }

  getGalleryImages() {
    return axios.get(
      `//graph.facebook.com/v3.2/${this.fbGalleryId}/photos?limit=50&fields=id,images,name&access_token=${fbAccessToken}`
    );
  }

  componentDidMount() {
    GlobalEmitter.on(events.resize, this.onResize);
    BreakpointListener.on(events.breakpoint, this.onBreakpoint);

    if (
      this.props.items &&
      (!this.props.primary.facebook_gallery_id ||
        !this.props.primary.facebook_gallery_id.text)
    ) {
      this.items = this.props.items;
      this.setState(
        {
          isMobile: BreakpointListener.size === "mobile",
          total: this.items.length,
        },
        () => {
          this.imageChange(0);
        }
      );
    } else if (
      this.props.primary.facebook_gallery_id &&
      this.props.primary.facebook_gallery_id.text &&
      this.props.primary.facebook_gallery_id.text.length > 0
    ) {
      this.fbGalleryId = this.props.primary.facebook_gallery_id.text;
      console.log(`id`, this.fbGalleryId);

      this.getGalleryInfo().then((res) => {
        this.fbGalleryInfo = res.data.albums.filter(
          (g) => g.id === this.fbGalleryId && g.images && g.images.length > 0
        )[0];
        this.items = this.fbGalleryInfo.images.map(({ id, width, height }) => ({
          id,
          image: {
            url: `https://drive.google.com/uc?export=view&id=${id}`,
            width,
            height,
            aspect: height / width,
            aspectRatio: height / width,
          },
        }))
        console.log(this.fbGalleryInfo);
        this.setState(
          {
            isMobile: BreakpointListener.size === "mobile",
            total: this.items.length,
          },
          () => {
            this.imageChange(0);
          }
        );
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  componentWillUnmount() {
    GlobalEmitter.off(events.resize, this.onResize);
    BreakpointListener.off(events.breakpoint, this.onBreakpoint);
  }

  next = () => {
    let { currentIndex, total } = this.state;
    currentIndex++;
    if (currentIndex >= total) {
      currentIndex = 0;
    }
    this.imageChange(currentIndex);
  };

  prev = () => {
    let { currentIndex, total } = this.state;
    currentIndex--;
    if (currentIndex < 0) {
      currentIndex = total - 1;
    }
    this.imageChange(currentIndex);
  };

  imageChange(currentIndex) {
    let nextIndex = currentIndex + 1;
    if (nextIndex >= this.state.total) {
      nextIndex = 0;
    }
    this.setState(
      {
        currentIndex,
        height: this.getHeight(currentIndex),
      },
      () => {
        if (this.first) {
          this.first = false;
        }
      }
    );
  }

  getHeight = (currentIndex) => {
    if (!this.main) {
      return 0;
    }
    let fig = document.querySelector(`#${this.id} .current`);
    if (!fig) {
      return 0;
    }
    const image = this.items[
      currentIndex === undefined ? this.state.currentIndex : currentIndex
    ].image;
    if (!image) {
      return 0;
    }
    return Math.ceil(fig.getBoundingClientRect().width * (627 / 1223));
  };

  render() {
    const carouselId = this.id;
    const { heading } = this.props.primary;
    const { isMobile, currentIndex, total } = this.state;

    let nextIndex = currentIndex + 1;
    if (nextIndex >= total) {
      nextIndex = 0;
    }
    if (!this.items.length || !this.items[currentIndex]) {
      return null;
    }
    // console.log(this.items);
    return (
      <React.Fragment>
        <Columns mobile>
          <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
            {renderText(heading, `h4`)}
          </Columns.Column>
        </Columns>
        <Columns mobile id={carouselId} className={styles.gallery}>
          <Columns.Column
            mobile={{ size: 12 }}
            tablet={{ size: 10 }}
            className={`current ${cx({ hasImage: true, hasMainImage: true })}`}
            style={{ height: this.state.height }}
          >
            <article ref={(r) => (this.main = r)}>
              <PoseGroup animateOnMount={true} preEnterPose="preEnter">
                <GalleryItem key={`item-${currentIndex}`}>
                  {renderFluidImage(this.items[currentIndex].image)}
                </GalleryItem>
              </PoseGroup>
            </article>
          </Columns.Column>
          {this.items[nextIndex] && !isMobile && (
            <Columns.Column
              onClick={this.next}
              tablet={{ size: 2 }}
              className={`${cx({
                hasImage: true,
                hasNextImage: true,
              })}`}
            >
              <PoseGroup animateOnMount={true} preEnterPose="preEnter">
                <GalleryItem key={`item-next-${nextIndex}`}>
                  {renderFluidImage(this.items[nextIndex].image)}
                </GalleryItem>
              </PoseGroup>
            </Columns.Column>
          )}
        </Columns>
        <Columns mobile>
          <Columns.Column mobile={{ size: 3 }} tablet={{ size: 2 }}>
            <fieldset
              className={styles.controls}
              aria-label="carousel buttons"
              aria-controls={carouselId}
            >
              <button
                aria-label="previous"
                type="button"
                onClick={this.prev}
                className={cx({
                  carouselButton: true,
                  disabled: total <= 1,
                })}
              >
                <i className="icon" />
                Previous
              </button>
              <button
                aria-label="next"
                type="button"
                onClick={this.next}
                className={cx({
                  carouselButton: true,
                  disabled: total <= 1,
                })}
              >
                <i className="icon" />
                Next
              </button>
            </fieldset>
          </Columns.Column>
          <Columns.Column
            mobile={{ size: 2 }}
            tablet={{ size: 1 }}
            className={styles.counter}
          >
            <span>{currentIndex + 1}</span>
            <span>/{total}</span>
          </Columns.Column>
          <Columns.Column
            mobile={{ size: 7 }}
            tablet={{ size: 7 }}
            className={styles.caption}
          >
            {this.items[currentIndex] &&
              renderHtml(
                this.items[currentIndex].caption,
                "div",
                styles.caption
              )}
          </Columns.Column>
        </Columns>
      </React.Fragment>
    );
  }
}

export default ({ primary, items, theme = `white` }) => (
  <GallerySection theme={theme || primary.theme}>
    <ImageGallery primary={primary} items={items} />
  </GallerySection>
);
