function getGalleryName(heading) {
  if (!heading || !heading.text?.length) {
    return null;
  }
  return heading?.text?.toLowerCase().replace(/\s/g, "-");
}

function getImageId(alt, idx) {
  if (!alt) {
    return `image-${idx}`;
  }
  return alt?.toLowerCase().substr(0, 40).replace(/\s/g, "-");
}

export function getOgImageFromGallery(body) {
  try {
    if (typeof window === "undefined" || !body) {
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const gallery = searchParams.get("gallery");
    if (!gallery) {
      return;
    }
    const imageId = searchParams.get("item");
    if (!imageId) {
      return;
    }
    const galleryData = body.find(
      (slice) =>
        slice.slice_type === "photo_gallery_2024" &&
        getGalleryName(slice.primary.heading) === gallery
    );

    if (!galleryData) {
      return;
    }

    const item = galleryData.items.find((item, idx) => {
      const testId = getImageId(item.image.alt, idx);
      const result = testId === imageId;
      return result;
    });

    if (!item || !item.image) {
      return;
    }
    const image = item.image;
    const strippedUrl = image.url.split("?")[0];
    const url = `${strippedUrl}?q=90&fm=jpg&fit=crop&fit=crop&crop=faces&w=1200&h=600`;
    const dimensions = {
      width: 1200,
      height: 600,
    };
    const alt = item.image.alt;
    return { url, dimensions, alt };
  } catch (e) {
    return;
  }
}
